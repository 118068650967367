<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" v-loading="loading" :data="data">
        <el-table-column label="ID" width="150" prop="id"></el-table-column>
        <el-table-column label="图片" width="300px" prop="image">
            <template #default="scope">
                <el-image lazy :src="`https://color-cdn.rabigame.com/${scope.row.image}`"
                          style="width: 250px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" width="100px" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="动作" prop="type" width="200">
            <template #default="scope">
                {{ carousel_type_enum[scope.row.type] }}
            </template>
        </el-table-column>
        <el-table-column label="新手" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.rookie">新手活动</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="背景色" prop="color" width="100"></el-table-column>
        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="动作" prop="type" :rules="[{required: true, message: '请选择动作'}]">
                <el-select v-model="form.type" :disabled="loading" @change="changeType">
                    <el-option v-for="(v, k) in carousel_type_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图片id" prop="data.image_id" :rules="[{required: true, message: '请选择图片id'}]"
                          v-if="form.type === 0">
                <el-select v-model="form.data.image_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="image in $root.image_list" :label="image.id" :value="image._id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Collection" prop="data.collection_id"
                          :rules="[{required: true, message: '请选择collection'}]" v-if="form.type===2">
                <el-select v-model="form.data.collection_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in collection_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Gallery" prop="data.gallery_id"
                          :rules="[{required: true, message: '请选择gallery'}]" v-if="form.type===3">
                <el-select v-model="form.data.gallery_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in gallery_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Story" prop="data.story_id"
                          :rules="[{required: true, message: '请选择story'}]" v-if="form.type===4">
                <el-select v-model="form.data.story_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in story_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Jigsaw" prop="data.jigsaw_id"
                          :rules="[{required: true, message: '请选择jigsaw'}]" v-if="form.type===5">
                <el-select v-model="form.data.jigsaw_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in jigsaw_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Level" prop="data.level_id"
                          :rules="[{required: true, message: '请选择level'}]" v-if="form.type===6">
                <el-select v-model="form.data.level_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in level_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="切换页" prop="data.tab"
                          :rules="[{required: true, message: '请选择切换页'}]" v-if="form.type===7">
                <el-select v-model="form.data.tab" :disabled="loading" v-if="form.data" filterable>
                    <el-option label="发现页" :value="1"></el-option>
                    <el-option label="活动页" :value="2"></el-option>
                    <el-option label="我的页" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="地址" prop="data.url" :rules="[{required: true, message: '请输入地址'}]"
                          v-if="form.type===8">
                <el-input :disabled="loading" v-model="form.data.url" v-if="form.data"></el-input>
            </el-form-item>
            <el-form-item label="活动" prop="data.collection_event_id"
                          :rules="[{required: true, message: '请选择jigsaw'}]" v-if="form.type===10">
                <el-select v-model="form.data.collection_event_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in event_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订阅活动" prop="data.id" :rules="[{required: true, message: '请选择订阅活动'}]"
                          v-if="[9].includes(form.type)">
              <el-select v-model="subscriptionValue" :disabled="loading" filterable>
                <el-option v-for="c in subscription_list" :value="c.id" :label="c.name || c.sku"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="image" :rules="[{required: true, message: '请上传图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'carousel'}" :onProgress="handleProgress">
                    <img v-if="form.image" :src="`https://color-cdn.rabigame.com/${form.image}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="背景色" prop="color" :rules="[{required: true, message: '请选择背景色', validator}]">
                <el-color-picker v-model="form.color" :disabled="loading"></el-color-picker>
                <el-input v-model="form.color" :disabled="loading"
                          style="width: 100px;display: inline-block;margin-left: 20px"></el-input>
            </el-form-item>
            <el-form-item label="退回至" prop="back">
                <el-select v-model="form.back">
                    <el-option :value="0" label="图库页"></el-option>
                    <el-option :value="1" label="发现页"></el-option>
                    <el-option :value="2" label="活动页"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Carousel",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, collection_list: [],
            gallery_list: [], jigsaw_list: [], level_list: [], story_list: [], event_list: [],
            subscription_list: [],
            carousel_type_enum: {
                // 0: '进入图片',
                1: '进入当日Daily',
                2: '进入Collection',
                3: '进入Gallery',
                4: '进入Story',
                5: '进入Jigsaw',
                6: '进入Level',
                7: '切换Tab',
                8: '打开FB',
                9: '进入内购页',
                10: '进入收集活动',
            },
            form: {
                type: null,
                color: '',
                image: '',
                data: null,
                back: 0,
                active: false,
                rookie: false,
            },
        }
    },
    methods: {
        changeType() {
          this.form.rookie = false
          if (this.form.type === 0) {
                this.form.data = {
                    image_id: '',
                }
            } else if (this.form.type === 1) {
                this.form.data = null;
            } else if (this.form.type === 2) {
                this.form.data = {
                    collection_id: '',
                }
            } else if (this.form.type === 3) {
                this.form.data = {
                    gallery_id: '',
                }
            } else if (this.form.type === 4) {
                this.form.data = {
                    story_id: '',
                }
            } else if (this.form.type === 5) {
                this.form.data = {
                    jigsaw_id: '',
                }
            } else if (this.form.level === 6) {
                this.form.data = {
                    level_id: '',
                }
            } else if (this.form.type === 7) {
                this.form.data = {
                    tab: 1,
                }
            } else if (this.form.type === 8) {
                this.form.data = {
                    url: '',
                }
            } else if (this.form.type === 10) {
                this.form.data = {
                    collection_event_id: '',
                }
            } else if (this.form.type === 9) {
              this.form.data = {
                id: '',
              }
            }
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/carousel').then(res => {
                    this.data = res.data.data.carouselList;
                }),
                axios.get('/cms/v1/collection').then(res => {
                    this.collection_list = res.data.data.collectionList;
                }),
                axios.get('/cms/v1/gallery').then(res => {
                    this.gallery_list = res.data.data.galleryList;
                }),
                // axios.get('/cms/v1/story').then(res => {
                //     this.story_list = res.data.data.storyList;
                // }),
                axios.get('/cms/v1/jigsaw').then(res => {
                    this.jigsaw_list = res.data.data.jigsawList;
                }),
                axios.get('/cms/v1/level').then(res => {
                    this.level_list = res.data.data.levelList;
                }),
                axios.get('/cms/v1/collection-event').then(res => {
                    this.event_list = res.data.data.eventList;
                }),
                axios.get('/cms/v1/purchase/subscription-all').then(res => {
                  this.subscription_list = res.data.data.skuList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.form.data = deepcopy(item.data);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/carousel/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/carousel`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/carousel/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.image = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/carousel/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/carousel`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        validator(rule, value, callback) {
            if (/^#[A-Z0-9]{6}$/.test(value)) {
                callback();
            } else {
                callback('请选择或输入正确格式的颜色码');
            }
        },
    },
    computed: {
      subscriptionValue: {
        get() {
          return this.form.data.id
        },
        set(value) {
          this.form.data.id = value
          this.form.rookie = false
          this.subscription_list.forEach((subscription) => {
            if (this.form.data.id === subscription.id) {
              this.form.data.start = subscription.start
              this.form.data.end = subscription.end
              this.form.data.sku = subscription.sku
              this.form.rookie = subscription.newbie
            }
          })
        },
      }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>